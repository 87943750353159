@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'bootstrap-css-only/css/bootstrap.min.css';
@import 'mdbreact/dist/css/mdb.css';
@import 'bootstrap/dist/css/bootstrap.min.css';

:root {
	--hover-color: rgb(243, 240, 240);
	margin: 0 !important;
	padding: 0 !important;
}
.container {
	width: 100%;
	margin: 0;
	padding: 0;
}

body,
html {
	font-family: 'Roboto';
	--bs-font-sans-serif: 'Roboto';
	--bs-gutter-x: 0 !important;
	--bs-gutter-y: 0 !important;
}
.btn-danger {
	background-color: #dc2e20 !important;
}

.badge-danger {
	background-color: #dc2e20 !important;
}

tbody {
	--bs-table-striped-bg: #f5f5f5 !important;
	color: #707070;
}
thead {
	color: #707070;
}

table td {
	font-size: 16px;
	font-weight: 300;
}

.login-image {
	height: 100%;
}
.header {
	border: 1px solid #d4d4d4;
	box-shadow: none;
}
.login-form {
	border: 1px solid #d4d4d4;
	padding: 20px;
	width: 100%;
	justify-content: center;
}
.login {
	margin-top: 15%;
}
a {
	text-decoration: none;
	color: #dc2e20;
}
.profile-top-menu {
	display: flex;
}
.container {
	width: 100%;
}

/* form css */
.search-form {
	margin-right: 10px !important;
	border-radius: 4px;
	border: 1px solid #ced4da;
	height: 40px !important;
	overflow: hidden;
}

/* button */
.read-more-button {
	color: blue;
	cursor: pointer;
	text-decoration: underline;
}

.fa-icon {
	position: absolute;
	top: 10px;
	left: 50px;
}
form .fa {
	top: 13px;
	right: 3.5rem !important;
}
@media only screen and (min-width: 500px) {
	.TableData {
		overflow-x: hidden !important;
	}
}

@media only screen and (max-width: 600px) {
	.login {
		margin-top: 77px !important;
	}
	.login-form {
		margin-top: 79px;
	}
	.MuiButton-root {
		min-width: 22px !important;
	}
	body {
		overflow-x: hidden !important;
	}
	.container {
		max-width: 100% !important;
		overflow-x: hidden !important;
	}
	/*for send message button */
	.sendMessage-Button-1 {
		flex-direction: column;
		gap: 12px;
		width: 100%;
	}
	.sendMessage-Button-2 {
		flex-direction: column;
		width: 100%;
		gap: 12px;
	}
	.send-yourself-button {
		width: 100% !important;
	}
	.clear-all-button {
		width: 100% !important;
		margin-top: 6px;
	}
	.sendMessage-autoComplete {
		width: 100% !important;
	}
	.sendMessage-button {
		width: 100% !important;
		margin: 0 !important;
	}
}
@media only screen and (max-width: 575px) {
	.MuiButton-text {
		padding: 0 !important;
		/* margin-top: 8px !important; */
	}
	.toggle-button {
		padding-top: 8px !important;
	}
	.MuiButton-root {
		min-width: 22px !important;
	}
}

.pagination .page-item.active .page-link {
	color: #dc2e20 !important;
	background-color: #ffff !important;
}

.md-form.md-outline {
	margin-bottom: -0.5rem !important;
}
.md-form.md-outline label.active {
	color: #dc2e20 !important;
}

.MuiFormLabel-root,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated {
	background-color: white !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
	padding-bottom: 4px !important;
	font-size: 1rem !important;
	font-weight: 500 !important;
}
.Mui-focused:focus {
	color: #dc2e20 !important;
}

.MuiOutlinedInput-notchedOutline {
	border-color: #d4d4d4 !important;
}

.MuiAutocomplete-root,
.MuiAutocomplete-hasClearIcon,
.MuiAutocomplete-hasPopupIcon {
	width: 100% !important;
	min-height: 55px !important;
	max-height: auto;
}

.md-form.md-outline .form-control {
	padding-top: 10px !important;
}

.form-text {
	padding-top: 4px;
}
.md-form.md-outline input {
	height: 57px !important;
}
.md-form.md-outline label {
	transform: translateY(16px);
}
.md-form.md-outline input:focus {
	border-color: #dc2e20 !important;
	box-shadow: inset 0 0 0 1px #dc2e20 !important;
}
.form-control:focus {
	border-color: #dc2e20 !important;
	box-shadow: none !important;
}
.search-form:hover {
	border-color: #dc2e20 !important;
	/* box-shadow: none !important; */
}
.input-group-text {
	background-color: none !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
	border: 2px solid #dc2e20 !important;
	/* borderRadius: "5px 5px 0 0" */
}

.MuiOutlinedInput-inputAdornedEnd {
	color: black !important;
	background-color: none !important;
}

.MuiFormLabel-filled,
.Mui-focused {
	color: #dc2e20 !important;
}

/*navbar button */
.logout-button:hover {
	background-color: var(--hover-color);
}
.profile-button:hover {
	background-color: var(--hover-color);
}

/* floating Action Button */
.float-button {
	position: fixed !important;
	bottom: 50px !important;
	right: 15% !important;
	z-index: 999 !important;
}

.brand-div {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin: 0;
}
.navbar-brand {
	padding-bottom: 0px !important;
}
.brand-name {
	font-size: 22px;
	color: #000000;
	font-weight: normal;
}

.search-box-form {
	margin-right: 23px;
}
input[type='text'] {
	box-shadow: none !important;
}

/* toggle button */
@media only screen and (min-width: 995px) {
	.toggle-button {
		display: none !important;
	}
	.brand-name-short {
		display: none !important;
	}
	.search-box-form {
		display: none !important;
	}
}
@media only screen and (min-width: 769px) {
	/* data table pagination */
	/* .dataTables_paginate {
	background-color: red !important;
	text-align: center !important;
	float: left !important;
} */
}
@media only screen and (min-width: 1200px) {
	/* .pagination {
	margin-left: 2rem !important;
} */
}
.routes-content {
	/* align-items: center !important; */
	margin: 0 auto;
}

@media only screen and (max-width: 988px) {
	.brand-name {
		display: none !important;
	}
	.brand-name-short {
		display: block !important;
		color: #000000;
		font-weight: normal;
	}
	.routes-content {
		display: none !important;
	}
	.hr {
		display: none !important;
	}
	.export-button {
		float: none !important;
		/* width: 100vw !important */
	}
	.button-export {
		flex: 1 1 auto;
	}
	.search-button {
		flex: 1 1 auto;
	}
	.TableData .row .col-md-6 {
		margin-top: 12px !important;
		text-align: center !important;
	}
	.pagination-tab-link-active {
		border-bottom: none !important;
	}
	.manage-hint-component {
		margin-top: 20px;
	}
}
.side_nav_item:hover {
	background-color: #dc2e20 !important;
	color: #fff;
}
.side_nav_item {
	cursor: pointer !important;
}
.small-brand {
	font-size: 0.8rem;
}

.navbar-header-brand {
	display: flex !important;
	flex-direction: row !important;
	margin: 0;
}

.card {
	box-shadow: none !important;
}

.contact-card:hover {
	border: 2px solid #dc2e20;
}

/*  for add user form   */

.form-row {
	margin: 0 !important;
	padding: 0 !important;
}

/* for upload file */

.fileUpload {
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	color: #dc2e20;
	font-size: 1em;
	font-weight: bold;
	margin: 1em auto; /*20px/16px 0*/
	overflow: hidden;
	position: relative;
	text-align: center;
	align-items: center;
	width: auto;
	height: 100%;
}
/* .fileUpload:hover, .fileUpload:active, .fileUpload:focus {
  cursor: pointer;
} */
.fileUpload input.upload {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	font-size: 20px;
	opacity: 0;
	filter: alpha(opacity=0);
	height: 70px;
	margin: auto;
	border: 1px solid red;
}

input[type='file'] {
	position: fixed;
	cursor: pointer;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.custom-file-upload {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

/* modal  */

.my-modal-header {
	color: #dc2e20;
	font-size: 1.8rem;
}

/* MessageCard  */
.MessageCard:hover {
	/* background-color: #e7e3e3 ; */
	cursor: pointer;
	border: 2px solid #dc2e20 !important;
}

/*data table pagination*/

/*custom select */
.custom-select {
	width: 75px !important;
	height: 40px !important;
}

.MuiAutocomplete-inputRoot {
	position: relative !important;
}
.MuiAutocomplete-input {
	height: 16px !important;
}

/* message card style */

.message-title {
	font-weight: 400;
	font-size: 18px;
	color: #000000;
	margin-bottom: 10px;
}
.message-text {
	font-weight: 400;
	font-size: 16px;
	color: #707070;
}
.message-date {
	font-weight: 400;
	font-size: 13px;
	color: #727272;
}

/* export csv button in table data */

.export-button {
	float: right;
}
.routes-content {
	margin-left: 12px;
	border-bottom: 1px solid #d4d4d4;
	margin-bottom: 30px;
}

.routes-content > div {
	margin-right: 40px;
}

.col-selector .sorting {
	display: none !important;
}
.hr {
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 29px;
}
.pagination-tab-link {
	text-decoration: none;
	cursor: pointer;
	color: #727272;
}
.pagination-tab-link-active {
	/* text-decoration: underline; */
	color: #000000;
	font-weight: 500;
	font-size: 16px;
	border-bottom: 3px solid #db2a1d;
}
table thead {
	color: #000000 !important;
	font-weight: 400 !important;
}

/* create message form preview button */
.preview-div {
	position: relative;
	/* background-color: red; */
}
.preview-cross-button {
	position: absolute;
	top: -4px;
	right: 0px;
	background-color: white;
	border-radius: 50%;
}
.preview-cross-button:hover {
	cursor: pointer;
}

/* data table pagination */

.TableData .row .col-md-6 {
	width: 100% !important;
}

.TableData .row .col-md-5 {
	display: flex;
	justify-content: center;
	width: 100%;
}

.TableData .row .col-md-7 {
	width: 100% !important;
	margin: auto !important;
	text-align: center !important;
	align-items: center !important;
	justify-content: center !important;
	flex: none !important;
}
.TableData .dataTables_paginate {
	text-align: center !important;
	float: none !important;
	flex: 1 !important;
	vertical-align: middle !important;
	flex-shrink: 0 !important;
}
.TableData .pagination {
	margin: auto !important;
	flex-shrink: 0 !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
	justify-content: center !important;
}

/* message form button */

.sendMessage-Button-2 {
	display: flex;
	margin-right: 0;
}
.sendMessage-Button-1 {
	display: flex;
	margin-right: 0;
}
.send-yourself-button {
	width: 58%;
	margin-top: 6px;
	margin-right: 6px;
}
.clear-all-button {
	width: 41%;
	margin-top: 6px;
}
.sendMessage-autoComplete {
	width: 58%;
	margin-top: 6px;
}
.sendMessage-button {
	width: 41%;
	margin-left: 6px;
}
